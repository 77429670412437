export default [
    {
        header: 'Office Visit',
        icon: 'ActivityIcon',
        children: [
            {
                title: 'Patients',
                icon: 'UsersIcon',
                tagVariant: 'light-primary',
                route: 'patients',
                action: 'index',
                resource: 'patients',
            },
            {
                title: 'Appointment',
                icon: 'CalendarIcon',
                tagVariant: 'light-primary',
                route: 'appointment',
                action: 'index',
                resource: 'appointment',
            },
            {
                title: 'Office Visit',
                icon: 'UsersIcon',
                tagVariant: 'light-primary',
                route: 'office-visit',
                action: 'index',
                resource: 'office_visit',
            },
        ],

    }
]