export default [
    {
        header: 'Membership',
        action: 'index',
        resource: 'membership',
    },
    {
        title: 'Membership',
        icon: 'UsersIcon',
        tagVariant: 'light-warning',
        children: [
            {
                title: 'Types of Membership',
                icon: 'GridIcon',
                route: 'admin-memberships-types',
                action: 'index',
                resource: 'membershipTypes',
            },
            {
                title: 'Membership',
                icon: 'UsersIcon',
                route: 'admin-memberships',
                action: 'index',
                resource: 'membership',
            },
            {
                title: 'Lots',
                icon: 'GridIcon',
                route: 'admin-memberships-lotes',
                action: 'index',
                resource: 'membershipLotes',
            },
            // {
            //     title: 'Codes',
            //     icon: 'GridIcon',
            //     route: 'admin-memberships-code',
            //     action: 'index',
            //     resource: 'membershipCodes',
            // },
            
            {
                title: 'Users',
                icon: 'UsersIcon',
                route: 'admin-memberships-users',
                action: 'index',
                resource: 'membershipUsers',
            }
        ]
    },
]