export default [
  {
    header: 'Data and Reports',
    action: 'index',
    resource: 'module_data_and_reports',
  },
  {
    title: 'Admin Data',
    icon: 'BarChart2Icon',
    tagVariant: 'light-warning',
    route: 'report-admin-data',
    action: 'index',
    resource: 'module_data_and_reports.admin_data',
  },
  
]