export default [
    {
        header: 'Cash Account',
        title: 'Cash Account',
        icon: 'DollarSignIcon',
        tag: '1',
        tagVariant: 'light-primary',
        children: [
            {
                title: 'Cash Account',
                route: 'cash-account',
                action: 'index',
                resource: 'cash_accounts',
            },
            {
                title: 'Daily Boxes',
                route: 'cash-daily-boxes',
                action: 'index',
                resource: 'dailyBoxes',
            },
        ]
    }
]