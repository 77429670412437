export default [
    {
        header: 'Resources',
        children: [
            {
                title: 'Goal Score',
                icon: 'AwardIcon',
                tagVariant: 'light-primary',
                route: 'resource-goal-score',
                action: 'index',
                resource: 'goalScore',
            },
            {
                title: 'Information',
                icon: 'InfoIcon',
                tagVariant: 'light-primary',
                route: 'resource-information',
                action: 'index',
                resource: 'schedule',
            },
            {
                title: 'Blog',
                icon: 'BookIcon',
                tagVariant: 'light-primary',
                isExternalLink: true,
                route: 'https://blog.clinic360management.com/',
                action: 'index',
                resource: 'freeRoute',
            }
        ]
    },

]