export default [
  {
    header: 'Dashboard',
    title: 'Dashboard',
    icon: 'HomeIcon',
    tag: '1',
    tagVariant: 'light-warning',
    route: 'dashboard-analytics',
    action: 'index',
    resource: 'module_dashboard',
  },
]
