export default [
  {
    header: 'Administration',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Configurations',
        icon: 'AlertCircleIcon',
        tagVariant: 'light-warning',
        action: 'index',
        resource: 'administration',
        children: [
          {
            icon: 'ToolIcon',
            title: 'Configurations',
            children: [{
              title: 'Template',
              route: 'template',
              action: 'index',
              resource: 'config_app',
            }],

          },
          {
            icon: 'UsersIcon',
            title: 'Users',
            route: 'admin-users',
            action: 'index',
            resource: 'users',
          },
          {
            icon: 'MapIcon',
            title: 'States-Cities',
            children: [
              {
                title: 'States',
                route: 'states',
                action: 'index',
                resource: 'states'
              },
              {
                title: 'Cities',
                route: 'cities',
                action: 'index',
                resource: 'cities',
              }
            ]
          },
          {
            icon: 'ShieldIcon',
            title: 'Roles',
            route: 'roles',
            action: 'index',
            resource: 'roles',
          },
          {
            icon: 'HomeIcon',
            title: 'Facilities',
            route: 'admin-facilities',
            action: 'index',
            resource: 'facilities',
          },
          {
            icon: 'MapIcon',
            title: 'Regions',
            route: 'admin-regions',
            action: 'index',
            resource: 'regions',
          },
          {
            icon: 'ToolIcon',
            title: 'Appointment',
            children: [
              {
                title: 'Types of visits',
                route: 'admin-type-consult-visit',
                action: 'index',
                resource: 'type_visits',
              },
              {
                title: 'Visit Reason',
                route: 'admin-type-consult-consult',
                action: 'index',
                resource: 'consults',
              },
              {
                title: 'Visit Status',
                route: 'admin-visit-status',
                action: 'index',
                resource: 'visit_status',
              },
              {
                title: 'Medications',
                route: 'admin-type-medication',
                action: 'index',
                resource: 'medication',
              },
              {
                title: 'Allergies',
                route: 'admin-type-allergies',
                action: 'index',
                resource: 'allergies',
              },
              {
                title: 'Ailments',
                route: 'admin-type-ailment',
                action: 'index',
                resource: 'ailments',
              },
              {
                title: 'Tests',
                route: 'admin-type-test',
                action: 'index',
                resource: 'tests',
              },
              {
                title: 'Diagnostic imaging',
                route: 'admin-type-diagnostic-image',
                action: 'index',
                resource: 'diagnostic_imagings',
              },
              {
                title: 'Diagnostic Imaging Category',
                route: 'admin-diagnostic-imaging-category',
                action: 'index',
                resource: 'category_diagnostic_imagings',
              },
              {
                title: 'Procedures',
                route: 'admin-type-procedures',
                action: 'index',
                resource: 'procedures',
              },
              {
                title: 'Laboratories',
                route: 'admin-laboratory',
                action: 'index',
                resource: 'laboratory',
              },
              {
                title: 'Laboratories category',
                route: 'admin-category_lab',
                action: 'index',
                resource: 'category_lab',
              },
              {
                title: 'Review System',
                route: 'admin-review-system',
                action: 'index',
                resource: 'review_of_system',
              },
              {
                title: 'Review System Category',
                route: 'admin-category-review-system',
                action: 'index',
                resource: 'cat_review_of_system',
              },
              {
                title: 'Treatments',
                route: 'admin-treatment',
                action: 'index',
                resource: 'treatments',
              },
              {
                title: 'Diagnoses',
                route: 'admin-diagnoses',
                action: 'index',
                resource: 'diagnoses',
              },
              {
                title: 'Consult Price',
                route: 'admin-consult-price',
                action: 'index',
                resource: 'consult_prices',
              },
              {
                title: 'Used Cupons',
                route: 'admin-used-cupons',
                action: 'index',
                resource: 'used_cupons',
              },
              {
                title: 'Promotions',
                route: 'admin-promotion',
                action: 'index',
                resource: 'promotions',
              },
              {
                title: 'Promoters',
                route: 'admin-promoter',
                action: 'index',
                resource: 'promoters',
              },
              {
                title: 'Refunds',
                route: 'admin-refund',
                action: 'index',
                resource: 'refunds',
              },
            ]
          },
          {
            icon: 'AwardIcon',
            title: 'Goals',
            children: [
              {
                title: 'Goals',
                route: 'admin-goals',
                action: 'index',
                resource: 'goals',
              },
              {
                title: 'Goals Types',
                route: 'admin-types-goals',
                action: 'index',
                resource: 'goals_types',
              },
              {
                title: 'Quantifications',
                route: 'admin-quantifications',
                action: 'index',
                resource: 'quantifications',
              },
              {
                title: 'Goal Score',
                route: 'admin-goal-score',
                action: 'index',
                resource: 'goals',
              },
            ]
          },
          {
            icon: 'AlignJustifyIcon',
            title: 'View Form',
            route: 'admin-forms',
            action: 'index',
            resource: 'adminForms',
          },
          {
            icon: 'AlertCircleIcon',
            title: 'Tutorial',
            children: [
              {
                title: 'Categories',
                route: 'admin-types-tutorials',
                action: 'index',
                resource: 'categoryTutorials',
              },
              {
                title: 'Tutorials',
                route: 'admin-tutorials',
              },
            ]
          },

        ],
      },
      {
        title: 'Inventory',
        icon: 'ShoppingCartIcon',
        tagVariant: 'light-warning',
        action: 'index',
        resource: 'inventory',
        children: [
          {
            title: 'Shop',
            route: 'admin-inventory',
            action: 'index',
            resource: 'inventory',
          },
          /* {
            title: 'Details',
            route: { name: 'admin-inventory-product-details', params: { slug: 'apple-watch-series-5-27' } },
          }, */
        ],
      },
      {
        title: 'Admin Data',
        icon: 'BarChart2Icon',
        tagVariant: 'light-warning',
        route: 'admin-admin-data',
        action: 'index',
        resource: 'adminData',
      },
      {
        title: 'Survey',
        icon: 'FileTextIcon',
        tagVariant: 'light-warning',
        action: 'index',
        resource: 'adminData',
        children: [
          {
            title: 'Survey',
            route: 'admin-survey',
            action: 'index',
            resource: 'survey',
          },
          {
            title: 'Survey Results',
            route: 'admin-survey-results',
            action: 'index',
            resource: 'survey',
          }
        ]
      },
    ]
  }
]